import React from 'react'
import { graphql } from 'gatsby'
import { Box, Container, Typography, styled } from '@mui/material'

import LineBreaks from '_core/components/atoms/typography/LineBreaks'

import HomeScreen from 'public/components/organisms/sections/HomeScreen'
// import RegionStats from 'public/components/organisms/sections/RegionStats'
import Mandates from 'public/components/organisms/sections/Mandates'
import AccountBenefits from 'public/components/organisms/sections/AccountBenefits'
import Layout from 'public/components/organisms/global/Layout'
import { sectionPadding } from 'public/styles/section-padding'
import HeadingDivider from 'public/components/atoms/dividers/HeadingDivider'
import SectionJoin from 'public/components/atoms/dividers/SectionJoin'
import ImageTextPanel from 'public/components/molecules/panels/ImageTextPanel'
import BlogPostScroll from 'public/components/organisms/sections/BlogPostScroll'

const AboutHeadingContainer = styled(Container)(({ theme }) => ({
  ...sectionPadding(),
  [theme.breakpoints.up('lg')]: {
    paddingTop: `${theme.spacing(10)} !important`,
    paddingBottom: `${theme.spacing(3)} !important`,
  },
}))

interface Props {
  data: Queries.HomepageQuery
}

const Homepage = ({ data }: Props) => {
  const home = data.datoCmsHomepage
  const mandates = data.allDatoCmsPageMandate.edges
  const blogPosts = data.allDatoCmsBlogPost.edges

  return (
    <Layout
      metadata={{
        seoTags: home.seoMetaTags,
      }}
    >
      <HomeScreen
        mainHeading={home.heroMainHeadingNode.childMarkdownRemark.html}
        smallHeading={home.heroSmallHeading}
        keyId={home.originalId}
        breakpoint="lg"
      />
      <Box component="section" position="relative" bgcolor="common.white">
        <SectionJoin />
        <AboutHeadingContainer maxWidth="xl">
          <Typography variant="h1" component="h2">
            <LineBreaks
              keyId={`about-heading-${home.originalId}`}
              breakpoint="md"
              text={home.aboutMainHeading}
            />
          </Typography>
          <HeadingDivider sx={{ mb: 0 }} />
        </AboutHeadingContainer>
        <ImageTextPanel
          heading={home.aboutPanelHeading}
          image={home.aboutImage.gatsbyImageData}
          text={home.aboutPanelText}
          buttonLabel={home.aboutPanelButtonLabel}
          buttonLinkApiKey={home.aboutPanelButtonLink.model.apiKey}
          buttonLinkSlug={home.aboutPanelButtonLink.slug}
        />
      </Box>
      {/* <RegionStats stats={home.statisticsPanels} /> */}
      <Box bgcolor="lightGrey" mt={{ xs: 0, lg: 10 }}>
        <Mandates
          mandates={mandates}
          sectionJoin
          heading="Recent Mandates"
          buttonSlug="mandates"
          buttonLabel="View All Mandates"
          masonryProps={{
            masonryLayout: 'compact',
          }}
        />
      </Box>
      <AccountBenefits
        smallHeading={home.benefitsSmallHeading}
        mainHeading={home.benefitsMainHeading}
        imageLandscape={home.benefitsImageLandscape.gatsbyImageData}
        imagePortrait={home.benefitsImagePortrait.gatsbyImageData}
        benefits={home.accountBenefits}
      />
      <BlogPostScroll posts={blogPosts} heading={home.blogSectionHeading} />
    </Layout>
  )
}

export default Homepage

export const pageQuery = graphql`
  query Homepage {
    datoCmsHomepage {
      originalId
      heroMainHeadingNode {
        childMarkdownRemark {
          html
        }
      }
      heroSmallHeading
      heroBackgroundVideo {
        url
      }
      aboutMainHeading
      aboutImage {
        gatsbyImageData(
          width: 1200
          layout: FULL_WIDTH
          imgixParams: { ar: "3:2", fit: "crop", crop: "focalpoint" }
        )
      }
      aboutPanelHeading
      aboutPanelText
      aboutPanelButtonLabel
      aboutPanelButtonLink {
        ... on DatoCmsLandingPage {
          originalId
          model {
            apiKey
          }
          slug
        }
        ... on DatoCmsBasicPage {
          originalId
          model {
            apiKey
          }
          slug
        }
      }
      statisticsPanels {
        globalStatistics {
          placements
          coverage
          professionals
          regionsCovered
        }
        regions {
          ... on DatoCmsRegion {
            originalId
            region {
              name
              originalId
            }
            placements
            yearsOfCoverage
            professionals
            officerHires
          }
        }
      }
      benefitsImageLandscape: benefitsBackgroundImage {
        gatsbyImageData(
          width: 1600
          layout: FULL_WIDTH
          imgixParams: { ar: "1", fit: "crop", crop: "focalpoint" }
        )
      }
      benefitsImagePortrait: benefitsBackgroundImage {
        gatsbyImageData(
          width: 900
          layout: FULL_WIDTH
          imgixParams: { ar: "3:4", fit: "crop", crop: "focalpoint" }
        )
      }
      benefitsSmallHeading
      benefitsMainHeading
      accountBenefits {
        ... on DatoCmsBenefit {
          originalId
          benefit
        }
      }
      blogSectionHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsPageMandate(
      limit: 8
      sort: { fields: [active, meta___createdAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          ...mandateCard
        }
      }
    }
    allDatoCmsBlogPost(limit: 5, sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          ...BlogPostCard
        }
      }
    }
  }
`
